@import '~@blueprintjs/core/lib/scss/variables';

.sending {
  display: flex;
  justify-content: center;
  align-items: center;
	width: 80vw;
	height: 90vh;
	margin: 0 auto;
	& textarea {
		height: 10rem;
		margin-top: 1rem;
		border-color: $dark-gray2;
		font-size: 1.1rem;
	}
	& h1 {
		text-align: center;
	}
}

.sending-card {
	width: 100%;
}

.sending-button {
	margin-top: 1rem;
}
