/* ====== 共通スタイル ====== */

.home {
  text-align: center;
  width: 90vw;
  margin: 0 auto;
}

.home-main-title {
  margin: 0 auto 1rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #ffffff;
  font-size: 1.3rem;
  text-align: left;
}

.home-title-icon {
  padding: 0 0.3rem;
  font-weight: normal;
}

.home-gat-information-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ====== これまでに受け取ったGAT（GatStatus）用スタイル ====== */

.home-gat-status-wrapper {
  width: 25vw;
  & table {
    width: 100%;
    & th,
    td {
      text-align: center;
      font-size: 1.1rem;
    }
    & th {
      background: rgba(255, 255, 255, 0.1);
      font-size: 90%;
    }
  }
}

/* ====== GATを送信（GatSend）用スタイル ====== */

.home-gat-send-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 3vh auto;
  width: 90vw;
  align-items: center;
}

.home-gat-send-button-main {
  width: 60vw;
  text-align: left;
}

.home-gat-send-button-sub {
  width: 30vw;
  & > div {
    margin: 0 0 0 2rem;
  }
}

.home-gat-send-button-message {
  margin-bottom: 1rem;
  text-align: left;
  & h3 {
    font-size: 1.4rem;
    margin: 0.5rem 0 1rem;
    text-align: center;
    -webkit-animation: fadeIn 1.5s ease-in 0s 1 normal;
    animation: fadeIn 1.5s ease-in 0s 1 normal;
  }
  & div {
    padding: 0;
    font-size: 1rem;
    line-height: 1.8rem;
    & b {
      font-size: 120%;
    }
    & > p {
      padding: 1rem 1.5rem 1rem;
    }
  }
}

.home-gat-send-button-sub-callout {
  margin: 0.5rem 0;
}

/* ====== GAT送受信履歴（GatHistory）用スタイル ====== */

.home-gat-history-wrapper {
  margin: 0 2rem 4rem 0;
  width: 60vw;
  & table {
    width: 100%;
    & th,
    td {
      text-align: center;
    }
    thead {
      display: block;
    }
    tbody {
      display: block;
      overflow-y: scroll;
      height: 200px;
    }
    .home-gat-history-user {
      width: 10%;
      min-width: 9rem;
      font-weight: bold;
      font-size: 1.1rem;
    }
    .home-gat-history-month {
      width: 15%;
      min-width: 7rem;
    }
    .home-gat-history-comment {
      text-align: left;
      word-wrap: break-word;
    }
    .home-gat-history-empty {
      width: 100vw;
      font-size: 1rem;
    }
  }
}

/* ====== スマホ用スタイル（上書き処理） ====== */

@media screen and (max-width: 480px) {
  .home-gat-send-button-wrapper {
    flex-direction: row;
    justify-content: center;
  }

  .home-gat-send-button-main {
    width: 90vw;
  }

  .home-gat-send-button-message {
    & div {
      & > p {
        padding: 1rem;
      }
    }
  }

  .home-gat-send-button-sub {
    width: 90vw;
    & > div {
      margin: 1.5rem auto 0;
    }
  }

  .home-gat-history-wrapper {
    width: 90vw;
    margin-bottom: 3rem;
    & article {
      width: 100%;
      overflow: scroll;
    }
    & table {
      width: 150vw;
    }
  }

  .home-gat-status-wrapper {
    width: 90vw;
    margin: 0 auto 3rem;
  }
}
