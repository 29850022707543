.admin-employees {
  text-align: center;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.admin-employees-card {
  margin: 2rem;
  width: 45vw;
  min-width: 320px;
  height: 100%;
}

.admin-employees-sub-callout {
  margin: 2rem auto;
  max-width: 480px;
}

.admin-employees-sub-callout-description {
  width: 90%;
  margin: 1rem auto 0;
  text-align: left;
}

.admin-employees-sub-callout-button {
  text-align: center;
  margin: 1rem auto;
}

.admin-employees-table-wrapper {
  margin-top: 1rem;
  width: 100%;
  height: 70vh;
  overflow-y: scroll;
}

.admin-employees-table-wrapper > table {
  width: 100%;
}

.admin-employees-table-header.narrow,
.admin-employees-table-data.narrow {
  width: 6rem;
}

.admin-employees-table-header.very-narrow,
.admin-employees-table-data.very-narrow {
  width: 4rem;
}

.admin-employees-table-header.wide,
.admin-employees-table-data.wide {
  width: 15rem;
}

@media screen and (max-width: 480px) {
  .admin-employees-sub-callout {
    margin: 0 auto 2rem;
  }

  .admin-employees-sub-callout {
    width: 90%;
    justify-content: center;
  }

  .admin-employees-sub-callout-description {
    text-align: center;
  }

  .admin-employees-sub-callout-button {
    width: 100%;
  }
}
