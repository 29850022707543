.inquiry {
  text-align: center;
  width: 90vw;
  margin: 0 auto;
}

.inquiry-main-card {
  margin: 3rem auto;
  width: 100%;
}

.inquiry-form-url {
  margin: 1rem 0;
}

.inquiry-form-title {
  margin: 1rem 0;
}

.inquiry-feature-list-title {
  margin: 2rem 0;
}

.inquiry-table {
  margin: 3rem auto 2rem;
}
