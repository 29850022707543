.admin {
  text-align: center;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.admin-card {
  margin: 2rem;
  width: 45vw;
  min-width: 320px;
  height: 100%;
  & > h2 {
    text-align: left;
    font-size: 1.2rem;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid #ffffff;
  }
  & h3 {
    font-size: 1.1rem;
    margin: 1rem auto;
  }
  &.two-thirds {
    width: 60vw;
  }
  &.one-third {
    width: 30vw;
  }
  &.full {
    width: 100%;
  }
}

.admin-title-icon {
  padding: 0.4rem;
  font-size: 75%;
  vertical-align: top;
}

.admin-analytics-page-link,
.admin-user-list-page-link {
  margin: 1rem auto;
}

.admin-csv-download-form,
.admin-csv-download-button {
  display: block;
  margin: 1rem auto;
  width: 60%;
}

.admin-card-description {
  width: 70%;
  max-width: 400px;
  margin: 1rem auto 0;
  text-align: left;
}

.admin-error-table-wrapper {
  margin-top: 1rem;
  width: 100%;
  height: 15rem;
  overflow: scroll;
}

.admin-error-table-wrapper > table {
  width: 100%;
}

.gat-tooltip-wrap {
  background-color: rgba(255, 0, 0, 0.25);
  .gat-tooltip-label {
    padding: 5px;
  }
}
