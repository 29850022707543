.admin-aggregate {
  width: 90vw;
  display: flex;
  flex-wrap: nowrap;
  margin: 2rem auto;
}

.admin-aggregate-sub-callout {
  margin: 2rem auto;
  max-width: 480px;
}

.admin-aggregate-sub-callout-description {
  width: 90%;
  margin: 1rem auto 0;
  text-align: left;
}

.admin-aggregate-sub-callout-button {
  text-align: center;
  margin: 1rem auto;
}

@media screen and (max-width: 480px) {
  .admin-aggregate-sub-callout {
    margin: 0 auto 2rem;
  }

  .admin-aggregate-sub-callout {
    width: 90%;
    justify-content: center;
  }

  .admin-aggregate-sub-callout-description {
    text-align: center;
  }

  .admin-aggregate-sub-callout-button {
    width: 100%;
  }
}
