@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

@import "./header.scss";
@import "./login.scss";
@import "./home.scss";
@import "./sending.scss";
@import "./inquiry.scss";
@import "./admin.scss";
@import "./admin-employees.scss";
@import "./admin-aggregate.scss";
@import "./notFound.scss";

body {
  background: $dark-gray2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
