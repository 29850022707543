@import "~@blueprintjs/core/lib/scss/variables";
 
.header {
  color: $pt-link-color;
  background: $dark-gray1;
}
.header-logo {
  display: block;
  & img {
    width: 140px;
    object-fit: contain;
    height: $pt-navbar-height;
  }
}