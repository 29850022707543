@import "~@blueprintjs/core/lib/scss/variables";

.login-logo {
  max-width: 460px;
  width: 80vw;
  margin: 7vh auto;
  & img {
    width: 100%;
    object-fit: contain;
  }
}

.login-processing {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-button-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80vw;
  height: 60vh;
  margin: 5vh auto;
  text-align: center;
  & h1 {
    margin-top: 0;
  }
  & .button-wrapper {
    margin: 1vh auto;
    & img {
      height: 50px;
      object-fit: cover;
    }
  }
  & p {
    line-height: 1.6rem;
  }
}

